<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handle(null, 'add')">添加权限</a-button>
      </div>

      <a-table
        ref="table"
        size="default"
        :rowKey="record => record.id"
        :columns="columns"
        :data-source="loadData"
        :scroll="defaultScroll"
        :loading="dataLoading"
      >
        <span slot="name" slot-scope="name">
          {{ name }}
        </span>
        <span slot="id" slot-scope="id">
          <a-tag color="blue">{{ id }}</a-tag>
        </span>
        <span slot="apis">
          <a-tag color="#2db7f5" class="myPointer" @click="LookApi">查看</a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handle(record, 'addChild')">添加权限</a>
            <a-divider type="vertical"></a-divider>
            <a @click="handle(record, 'edit')">编辑</a>
            <!-- <a-divider type="vertical"></a-divider>
            <a @click="handle(record, 'del')">删除</a> -->
          </template>
        </span>
      </a-table>

      <a-modal
        title="刪除"
        :visible="del.visible"
        :confirm-loading="del.confirmLoading"
        @ok="handleDeleteOk"
        @cancel="handleCancel('del')"
      >
        <p>{{ del.ModalText }}</p>
      </a-modal>

      <create-form
        ref="createModal"
        :visible="visible"
        :loading="confirmLoading"
        :model="mdl"
        :edit="editModal"
        @cancel="handleCancel('add')"
        @ok="handleOk"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { authTree, addAuths, delAuths, getAuths, patchAuths, putAuthsParent } from '@/api/auths'

import CreateForm from './modules/CreateAuthForm'
import FindCitys from './modules/FindCitys'

const columns = [
  {
    title: '权限名称',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' }
  },
  {
    title: 'ID',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' }
  },
  {
    title: '操作',
    dataIndex: 'action',
    fixed: 'right',
    ellipsis: true,
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'OpenList',
  components: {
    STable,
    Ellipsis,
    CreateForm,
    FindCitys
  },
  data() {
    this.columns = columns
    return {
      noticeTitle: '权限设置',
      // create model
      visible: false,
      confirmLoading: false,
      mdl: null,
      editModal: false,
      addChild: false,
      parentId: null,
      // 批量删除 modal
      del: {
        ModalText: '您要删除此权限吗',
        visible: false,
        confirmLoading: false
      },
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {
        keyword: ''
      },
      search: {
        visible: false
      },
      // 加载数据
      loadData: [],
      dataLoading: false,
      selectedRowKeys: [],
      selectedRows: []
    }
  },
  filters: {},
  created() {},
  mounted() {
    this.fetch()
  },
  computed: {
    host() {
      return this.$store.state.oss.host
    }
  },
  methods: {
    handle(record = null, type = 'add') {
      const form = this.$refs.createModal.form
      switch (type) {
        case 'add':
          form.resetFields()
          this.editModal = false
          this.mdl = record
          this.visible = true
          break
        case 'edit':
          this.visible = true
          this.editInfo(record, data => {
            this.editModal = true
            this.mdl = { ...data }
          })
          break
        case 'addChild':
          this.visible = true
          form.resetFields()
          this.editInfo(record, data => {
            this.parentId = data.id
            this.editModal = false
            this.addChild = true
          })
          break
        case 'del':
          this.mdl = { ...record }
          this.del.visible = true
          break
      }
    },
    handleCancel(type) {
      switch (type) {
        case 'modal':
          this.mdl = null
          this.visible = false
          break
        case 'del':
          this.del.visible = false
          break
        case 'delCancel':
          this.del.visible = false
          this.confirmLoading = false
          break
        default:
          this.mdl = null
          this.visible = false
          this.roleVisible = false
          break
      }
    },
    resetTable(handle, msg = null, type = 'success') {
      const form = this.$refs.createModal.form
      switch (handle) {
        case 'add':
          if (!msg) {
            msg = '添加成功。'
          }
          this.confirmLoading = false
          form.resetFields()
          this.visible = false
          break
        case 'edit':
          if (!msg) {
            msg = '修改成功。'
          }
          this.confirmLoading = false
          form.resetFields()
          this.visible = false
          break
        case 'del':
          if (!msg) {
            msg = '删除成功。'
          }
          this.confirmLoading = false
          this.del.visible = false
          break
        default:
          if (msg) {
            msg = '失败'
          }
          type = 'error'
          form.resetFields()
          this.confirmLoading = false
          this.visible = false
          this.dataLoading = false
          this.del.visible = false
          break
      }
      this.fetch()
      this.$notification[type]({
        message: this.noticeTitle,
        description: msg
      })
    },
    fetch(parentId = null, plat = 'web') {
      this.dataLoading = true
      let params = {
        plat
      }
      if (parentId) {
        params = { parentId, ...params }
      } else {
        authTree(params)
          .then(res => {
            console.log(res, 'res auth')
            console.log(params, 'params auth')
            const data = res.data
            this.emptyChildren(data)
            this.loadData = data
            this.dataLoading = false
          })
          .catch(error => {
            this.resetTable(null, `tree ${error}`)
          })
      }
    },
    getRowInfo(id, cb) {
      getAuths(id)
        .then(res => {
          console.log(res, 'this user')
          cb(res)
        })
        .catch(error => {
          this.resetTable(null, `row ${error}`)
        })
    },
    editInfo(record, cb) {
      this.getRowInfo(record.id, data => {
        cb(data)
      })
    },
    addChildAuth(data) {
      addAuths(data)
        .then(res => {
          const authId = res.id
          const parent = this.host + '/rest/auths/' + this.parentId
          putAuthsParent(authId, parent)
            .then(res => {
              this.resetTable('add')
            })
            .catch(error => {
              this.resetTable('put parent', `${error}`, 'error')
            })
        })
        .catch(error => {
          this.resetTable('add', `${error}`, 'error')
        })
    },
    handleOk() {
      const form = this.$refs.createModal.form
      form.validateFields((errors, values) => {
        if (!errors) {
          this.confirmLoading = true
          const requestParameters = { ...values }
          const { mdl, addChild } = this
          if (mdl) {
            const id = mdl.id

            patchAuths(id, requestParameters)
              .then(res => {
                this.resetTable('edit')
              })
              .catch(error => {
                this.resetTable('edit', `${error}`, 'error')
              })
          } else {
            if (addChild) {
              this.addChildAuth(requestParameters)
            } else {
              addAuths(requestParameters)
                .then(res => {
                  this.resetTable('add')
                })
                .catch(error => {
                  this.resetTable('add', `${error}`, 'error')
                })
            }
          }
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleDeleteOk(e) {
      this.del.ModalText = '将在两秒后删除此项'
      this.del.confirmLoading = true
      const { mdl } = this
      setTimeout(() => {
        this.del.confirmLoading = false
        this.del.ModalText = '您要删除' + mdl.name + '?'
        this.confirmLoading = true
        if (this.del.visible !== false) {
          delAuths(mdl.id)
            .then(res => {
              this.resetTable('del')
            })
            .catch(error => {
              this.resetTable('del', `${error}`, 'error')
            })
        } else {
          this.handleCancel('delCancel')
        }
      }, 2000)
    },
    /**
     * 查看API
     */
    LookApi() {},
    fetchApi(id) {},
    /**
     * FindCitys
     * $emit
     */
    getValue(data) {
      this.queryParam.keyword = data
    },
    findByKeyword() {
      this.search.visible = true
      this.queryParam.keyword = this.queryParam.keyword !== undefined ? this.queryParam.keyword : ''
      this.$refs.table.refresh()
    },
    /**
     * @param id
     */
    emptyChildren(arr) {
      arr.forEach(item => {
        if (item.children !== undefined && item.children !== null) {
          if (item.children.length === 0) {
            delete item.children
          } else {
            this.emptyChildren(item.children)
          }
        }
      })
    }
  }
}
</script>
