<template>
  <div>
    <a-select
      allow-clear
      show-search
      :value="keyword"
      :default-active-first-option="false"
      :show-arrow="false"
      :filter-option="false"
      :not-found-content="fetching ? undefined : null"
      @search="handleSearch"
      @change="handleChange"
      @focus="findAll"
    >
      <template v-if="resultType === 'value'">
        <a-spin v-if="fetching" slot="notFoundContent" size="small" />
        <a-select-option v-for="d in data" :key="d.value">
          {{ d.text }}
        </a-select-option>
      </template>
      <template v-else>
        <a-spin v-if="fetching" slot="notFoundContent" size="small" />
        <a-select-option v-for="d in data" :key="d.id">
          {{ d.text }}
        </a-select-option>
      </template>
    </a-select>
  </div>
</template>

<script>
import { queryAddr } from '@/api/addrs'

export default {
  name: 'FindCity',
  props: {
    keyword: {
      type: String,
      default: ''
    },
    level: {
      type: String,
      default: 'city'
    },
    opened: {
      type: Boolean,
      default: true
    },
    resultType: {
      type: String,
      default: 'value'
    }
  },
  data() {
    return {
      // search
      data: [],
      timeout: '',
      currentValue: '',
      fetching: false
    }
  },
  methods: {
    // search
    handleSearch(value) {
      this.fetch(value, data => (this.data = data))
    },
    handleChange(value, node) {
      this.$emit('emitValue', value)
      this.value = value
      this.fetch(value, data => (this.data = data))
    },
    findAll(value) {
      this.fetch('', data => (this.data = data))
    },
    fetch(value, callback) {
      let { timeout } = this
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }
      this.currentValue = value
      this.fake(value, callback)

      // timeout = setTimeout(this.fake(value, callback), 300)
    },
    fake(value, callback) {
      this.fetching = true
      const { currentValue, opened } = this
      const params = {
        keyword: currentValue,
        opened,
        level: this.level,
        page: 0,
        size: 1000,
        sort: ''
      }
      queryAddr(params).then(d => {
        if (currentValue === value) {
          const result = d.data.content
          const data = []
          result.forEach(r => {
            data.push({
              id: r.id,
              value: r.name,
              text: r.name
            })
          })
          callback(data)
          this.fetching = false
        }
      })
    }
  }
}
</script>
