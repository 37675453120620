<template>
  <a-modal
    title="权限"
    :width="modalWidth"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <template slot="footer">
      <span class="sortDescription">ID生成后后将不能修改</span>
      <a-button key="back" @click="() => { $emit('cancel') }">
        取消
      </a-button>
      <a-button key="submit" :loading="loading" type="primary" @click="() => { $emit('ok') }">
        确定
      </a-button>
    </template>
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-form-item label="名称">
          <a-input allow-clear v-decorator="['name', {rules: [{required: true, message: '请输入名称'}]}]" :maxLength="30" />
        </a-form-item>
        <a-form-item label="ID">
          <a-input allow-clear v-decorator="['id', {rules: [{required: true, message: '请输入ID'}]}]" :maxLength="30" :disabled="edit" />
        </a-form-item>
        <a-form-item label="权限类型">
          <a-radio-group v-decorator="['plat', { initialValue: 'web' }]">
            <a-radio value="web">
              web
            </a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'

// 表单字段
const fields = ['name', 'id', 'plat']

export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    },
    edit: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      form: this.$form.createForm(this)
    }
  },
  created () {

    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))

    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  }
}
</script>
